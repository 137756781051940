<mat-card-title>
  <div class="row justify-content-between mb-3">
    <div class="col-auto h2"><b>Редактировать карточку клиента.</b></div>
    <div class="col-auto">
      <button
        type="button"
        mat-icon-button
        class="btn btn-primary btn-sm"
        matTooltip="Закрыть"
        matTooltipPosition="left"
        matTooltipClass="tooltip"
        matDialogClose
      >
        <img src="assets/img/icon/close/Icon_Close.svg" alt="close" />
      </button>
    </div>
  </div>
</mat-card-title>

<mat-card-content>
  <form [formGroup]="formUser" id="formUser" (ngSubmit)="editUser()">
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>Имя</mat-label>
          <input matInput type="text" formControlName="name" />
          <mat-error *ngIf="formUser.get('name').hasError('required')">
            Введите имя получателя
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>Фамилия</mat-label>
          <input matInput type="text" formControlName="lastName" />
          <mat-error *ngIf="formUser.get('lastName').hasError('required')">
            Введите фамилию получателя
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>Отчество</mat-label>
          <input matInput type="text" formControlName="middleName" />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-checkbox color="primary" formControlName="viber"
          >Viber</mat-checkbox
        >

        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>Телефон<span class="text-danger">*</span></mat-label>
          <input
            matInput
            type="text"
            prefix="+38"
            [mask]="' (000) 000-00-99'"
            [readonly]="!editMainPhone || editMainPhone.length == 0"
            formControlName="phone"
          />
          <mat-error *ngIf="formUser.get('phone').hasError('required')">
            Введите телефон
          </mat-error>
          <mat-error *ngIf="formUser.get('phone').hasError('minlength')">
            Номер телефона некорректный
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-checkbox color="primary" formControlName="viberDop"
          >Viber</mat-checkbox
        >
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label
            >Телефон дополнительный<span class="text-danger">*</span></mat-label
          >
          <input
            matInput
            type="text"
            prefix="+38"
            [mask]="' (000) 000-00-99'"
            formControlName="phoneDop"
          />
          <mat-error *ngIf="formUser.get('phoneDop').hasError('minlength')">
            Номер телефона некорректный
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>Email</mat-label>
          <input matInput type="email" formControlName="email" />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-form-field *ngIf="typeList$ | async as typeList">
          <mat-label>Тип клиента</mat-label>
          <mat-select formControlName="type">
            <mat-option></mat-option>
            <mat-option *ngFor="let item of typeList" [value]="item.value">{{
              item.text
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field *ngIf="specInfoList$ | async as specInfoList">
          <mat-label>Спец. инофрмаци о клиенте</mat-label>
          <mat-select formControlName="badType">
            <mat-option></mat-option>
            <mat-option
              *ngFor="let item of specInfoList"
              [value]="+item.value"
              >{{ item.text }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field *ngIf="actionList$ | async as actionList">
          <mat-label>Статус клиента</mat-label>
          <mat-select formControlName="action">
            <mat-option *ngFor="let item of actionList" [value]="+item.value">{{
              item.text
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field *ngIf="managerList$ | async as managerList">
          <mat-label>Закрепленный менеджер</mat-label>
          <mat-select formControlName="manager">
            <mat-option></mat-option>
            <mat-option
              *ngFor="let item of managerList"
              [value]="+item.value"
              >{{ item.text }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>

    </div>
    <div class='row'>
      <div class="col">
        <mat-form-field *ngIf="cashBackList$ | async as cashBackList">
          <mat-label>Программа лояльности</mat-label>
          <mat-select formControlName="cashBackRate">
            <mat-option
              *ngFor="let item of cashBackList"
              [value]="+item.value"
            >{{ item.text }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field *ngIf="unitList$ | async as unitList">
          <mat-label>Закрепленная СЕ</mat-label>
          <mat-select formControlName="unit">
            <mat-option
              *ngFor="let item of unitList"
              [value]="+item.value"
            >{{ item.text }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class='row'>
      <div class="col">
        <mat-form-field *ngIf="sexList$ | async as sexList">
          <mat-label>Пол</mat-label>
          <mat-select formControlName="sex">
            <mat-option
              *ngFor="let item of sexList"
              [value]="item.value"
            >{{ item.text }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-card-content>

<mat-dialog-actions align="end">
  <button mat-button class="btn btn-danger" type="button" matDialogClose>
    Отмена
  </button>
  <button mat-button class="btn btn-success" type="submit" form="formUser">
    Сохранить
  </button>
</mat-dialog-actions>
