<ng-container *ngIf='editNomen'>
  <div class='row'>
    <div class='col'>
      <mat-slide-toggle color='primary' #slideForm>{{slideForm.checked ? 'Заменить' : 'Назначить'}}</mat-slide-toggle>
    </div>
    <div class='col'>
      <mat-checkbox color='primary' [formControl]='isValidate' (change)='setValidator()'
      >{{isValidate.value ? 'Проверка выключена' : 'Проверка включена'}}</mat-checkbox
      >
    </div>
  </div>

  <div class='row' *ngIf='slideForm.checked'>
    <div class='col-auto'>
      <form [formGroup]='formReplace' (ngSubmit)='setReplaceToProd()'>
        <div class='row'>
          <div class='col-12'>
            <mat-form-field appearance='outline'>
              <mat-label>Найти (Ru)</mat-label>
              <input matInput formControlName='fromRu' />
            </mat-form-field>
            <mat-form-field appearance='outline'>
              <mat-label>Заменить (Ru)</mat-label>
              <input matInput formControlName='toRu' />
            </mat-form-field>
          </div>
          <div class='col-12'>
            <mat-form-field appearance='outline'>
              <mat-label>Найти (Ua)</mat-label>
              <input matInput formControlName='fromUa' />
            </mat-form-field>
            <mat-form-field appearance='outline'>
              <mat-label>Заменить (Ua)</mat-label>
              <input matInput formControlName='toUa' />
            </mat-form-field>
          </div>
        </div>
        <button mat-button class='btn btn-success'>Заменить</button>
      </form>
    </div>
  </div>
  <div class='row' *ngIf='!slideForm.checked'>
    <div class='col-auto'>
      <form [formGroup]='form' (ngSubmit)='setNomenToProd()'>
        <div class='row'>
          <div class='col'>
            <mat-form-field appearance='outline'>
              <mat-label>Номенклатура (RU)</mat-label>
              <input matInput formControlName='nomenRu' />
            </mat-form-field>
            <mat-form-field appearance='outline'>
              <mat-label>Номенклатура (UA)</mat-label>
              <input matInput formControlName='nomenUa' />
            </mat-form-field>
            <button mat-button class='btn btn-success'>Сохранить</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>


<p-table
  [columns]='resultData.cols'
  [value]='resultData.rows'
  [rowsPerPageOptions]='[10, 20,25, 50, 100,200,500,1000,resultData.rows.length]'
  [paginator]='true'
  currentPageReportTemplate='Элементов на странице ({first} - {last}) з {totalRecords} '
  [filterDelay]='0'
  autoLayout='true'
  [rows]='20'
  sortMode='multiple'
  selectionMode='single'
  (onRowSelect)='onRowSelect()'
  [(selection)]='selectedProduct'
  [globalFilterFields]='searchFieldList'
  #dt1
  styleClass='p-datatable-gridlines p-datatable-sm'
>

  <ng-template pTemplate='caption'>
    <div class='row justify-content-between'>
      <div *ngIf='selected'>Всего выбрано {{selectedProduct.length}} строчек</div>
      <div class='col' *ngIf='searchFieldList?.length>0'>
        <div class='text-right'>
                <span class='p-input-icon-left p-ml-auto'>
                  <i class='pi pi-search'></i>
                  <input
                    pInputText
                    type='text'
                    (input)="dt1.filterGlobal($event.target.value, 'contains')"
                    placeholder='Поиск'
                  />
                </span>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate='header' let-columns>
    <tr>
      <th style='width: 2rem' class='text-center' *ngIf='selected'>
        <p-tableHeaderCheckbox (click)='onRowSelect()'></p-tableHeaderCheckbox>
      </th>
      <th
        *ngFor='let col of columns'
        [pSortableColumn]='col.field'
        style='font-weight: 400; font-size: 12px'
      >
        <ng-container [ngSwitch]='col.field'>
          <ng-container *ngSwitchCase="'countProvider'">
            <div
              class='p-d-flex p-jc-between p-ai-center'
              style='font-size: 12px;width: 200px;'
            >
              Количество у поставщика<br />
              <table class='table table-bordered table-sm mb-0'>
                <tr class='text-center p-0'>
                  <td class='text-center p-0'>
                    <span> 0-1 </span>
                  </td>
                  <td class='text-center p-0'>1-2</td>
                  <td class='text-center p-0'>2-3</td>
                  <td class='text-center p-0'>Под заказ</td>
                </tr>
              </table>
            </div>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <p-sortIcon [field]='col.field'></p-sortIcon>
            {{ col.header }}
          </ng-container>
        </ng-container>

      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate='body' let-rowData let-columns='columns' let-i='rowIndex'>
    <tr (click)='rowClick(rowData)'>
      <td class='text-center' *ngIf='selected'>
        <p-tableCheckbox [value]='rowData' [index]='rowIndex'></p-tableCheckbox>
      </td>
      <td
        *ngFor='let col of columns'
        style='font-weight: 300; font-size: 12px'
        [ngClass]="rowData['rowClass']"
        (click)='colClick(rowData,col.field)'
      >
        <ng-container [ngSwitch]='col.field'>
          <ng-container *ngSwitchCase="'rowNumber'">
            {{i + 1}}
          </ng-container>
          <ng-container *ngSwitchCase="'orderNumber'">
            <a [href]="'/crm/order-card/'+rowData[col.field]" target='_blank' class='link'> {{ rowData[col.field] }}</a>
          </ng-container>
          <ng-container *ngSwitchCase="'taskId'">
            <a [href]="'/crm/order-card/'+rowData['orderNumber']+'?task='+rowData[col.field]" target='_blank'
               class='link'> {{ rowData[col.field] }}</a>
          </ng-container>
          <ng-container *ngSwitchCase="'requestId'">
            <a [href]="'/crm/order-card/'+rowData['orderNumber']+'?requestProd='+rowData['productId']" target='_blank'
               class='link'> {{ rowData[col.field] }}</a>
          </ng-container>
          <ng-container *ngSwitchCase="'pretensionId'">
            <a [href]="'/crm/pretension/'+rowData[col.field]" target='_blank' class='link'> {{ rowData[col.field] }}</a>
          </ng-container>
          <ng-container *ngSwitchCase="'katalNumber'">
            <a [href]="'/crm/product-edit-card/'+rowData[col.field]+'/'+rowData['brand']" target='_blank'
               class='link'> {{ rowData[col.field] }}</a>
          </ng-container>
          <ng-container *ngSwitchCase="'lastResult'">
            <div [innerHTML]='rowData[col.field]'></div>
          </ng-container>
          <ng-container *ngSwitchCase="'comment'">
            <div [innerHTML]='rowData[col.field]'></div>
          </ng-container>
          <ng-container *ngSwitchCase="'link'">
            <a [href]='rowData[col.field]' target='_blank'
               class='link'> {{ rowData[col.field] }}</a>
          </ng-container>
          <ng-container *ngSwitchCase="'nomenCard'">
            <span [ngClass]="rowData['nomenColor']">{{ rowData[col.field] }}</span>
          </ng-container>
          <ng-container *ngSwitchCase="'saleCost'">
            {{ rowData[col.field] }} грн.
          </ng-container>
          <ng-container
            *ngSwitchCase="'countProvider'"
          >
            <div style='width:200px'>
              <lib-shared-provider-count-table
                [providerInfo]='rowData.countProvider'
                [header]='false'
              ></lib-shared-provider-count-table>
            </div>

          </ng-container>
          <ng-container *ngSwitchCase="'photoImg'">
            <img [src]='rowData[col.field] ' alt='' width='100'>
          </ng-container>
          <ng-container *ngSwitchCase="'orderCar'">
            <span [ngClass]="rowData['orderCarColor']">{{ rowData[col.field] }}</span>
          </ng-container>
          <ng-container *ngSwitchCase="'photo'">
            <span [ngClass]="rowData['photoColor']">{{ rowData[col.field] }}</span>
          </ng-container>
          <ng-container *ngSwitchCase="'oe'">
            <span [ngClass]="rowData['colorOe']">{{ rowData[col.field] }}</span>
          </ng-container>
          <ng-container *ngSwitchCase="'prodGrp'">
            <span [ngClass]="rowData['prodGrpColor']">{{ rowData[col.field] }}</span>
          </ng-container>
          <ng-container *ngSwitchCase="'alertCount'">
            <span [ngClass]="rowData['alertCountColor']" [matTooltip]="'Предыдущий импорт ' + rowData['previousImport']"
                  matTooltipClass='tooltip'>{{ rowData[col.field] }}</span>
          </ng-container>

          <ng-container *ngSwitchCase="'alertHeader'">
            <span [ngClass]="rowData['alertHeaderColor']">{{ rowData[col.field] }}</span>
          </ng-container>

          <ng-container *ngSwitchCase="'alertCost'">
            <span [ngClass]="rowData['alertCostColor']">{{ rowData[col.field] }}</span>
          </ng-container>

          <ng-container *ngSwitchCase="'countCheck'">
            <div class='text-center w-100' style='font-size: 18px'
                 [ngClass]="rowData['countCheckClass']">{{ rowData[col.field] }}</div>
          </ng-container>

          <ng-container *ngSwitchCase="'action'">
            <div style='display: grid; grid-template-columns: 1fr 1fr 1fr'>
              <ng-container *ngIf='actionState.edit'>
                <mat-icon matTooltip='Редакция' matTooltipClass='tooltip' class='cursor-pointer' style='font-size: 16px'
                          (click)='edit(rowData)'>edit
                </mat-icon>
              </ng-container>

              <ng-container *ngIf='actionState.info'>
                <mat-icon matTooltip='Информация' matTooltipClass='tooltip' class='cursor-pointer'
                          style='font-size: 16px'
                          (click)='info(rowData)'>info
                </mat-icon>
              </ng-container>
              <ng-container *ngIf='actionState.showMore'>
                <mat-icon matTooltip='Информация' matTooltipClass='tooltip' class='cursor-pointer'
                          style='font-size: 16px'
                          (click)='showMore(rowData)'>chevron_right
                </mat-icon>
              </ng-container>
              <ng-container *ngIf='actionState.delete'>
                <mat-icon matTooltip='Удалить' matTooltipClass='tooltip' class='cursor-pointer' style='font-size: 16px'
                          (click)='delete(rowData)'>delete
                </mat-icon>
              </ng-container>
              <ng-container *ngIf='actionState.upload'>
                <mat-icon matTooltip='Обновить' matTooltipClass='tooltip' class='cursor-pointer' style='font-size: 16px'
                          (click)='upload(rowData)'>upload
                </mat-icon>
              </ng-container>
              <ng-container *ngIf='actionState.add'>
                <mat-icon matTooltip='Добавить' matTooltipClass='tooltip' class='cursor-pointer' style='font-size: 16px'
                          (click)='addProd(rowData)'>add
                </mat-icon>
              </ng-container>
              <ng-container *ngIf='actionState.addBarcode'>
                <mat-icon matTooltip='Добавить штрихкод по товару' matTooltipClass='tooltip' class='cursor-pointer'
                          style='font-size: 16px'
                          (click)='addBarcode(rowData)'>document_scanner
                </mat-icon>
              </ng-container>
              <ng-container *ngIf='actionState.refresh'>
                <mat-icon matTooltip='Обновить' matTooltipClass='tooltip' class='cursor-pointer' style='font-size: 16px'
                          (click)='refresh(rowData)'>refresh
                </mat-icon>
              </ng-container>
              <ng-container *ngIf='actionState.reset'>
                <mat-icon matTooltip='Сбросить' matTooltipClass='tooltip' class='cursor-pointer' style='font-size: 16px'
                          (click)='reset(rowData)'>refresh
                </mat-icon>
              </ng-container>
              <ng-container *ngIf='actionState.on && +rowData.actionId == 1'>
                <mat-icon matTooltip='Включено' matTooltipClass='tooltip' class='cursor-pointer text-success '
                          style='font-size: 16px'
                          (click)='offClick(rowData)'>toggle_on
                </mat-icon>
              </ng-container>
              <ng-container *ngIf='actionState.link'>
                <a [href]="rowData['link']" target='_blank'>
                  <mat-icon matTooltip='Перейти' matTooltipClass='tooltip' class='cursor-pointer '
                            style='font-size: 16px'
                  >link
                  </mat-icon>
                </a>
              </ng-container>
              <ng-container *ngIf='actionState.off && +rowData.actionId == 0'>
                <mat-icon matTooltip='Выключено' matTooltipClass='tooltip' class='cursor-pointer text-danger '
                          style='font-size: 16px'
                          (click)='onClick(rowData)'>toggle_off
                </mat-icon>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngSwitchDefault>
            {{ rowData[col.field] }}
          </ng-container>
        </ng-container>
      </td>
    </tr>
  </ng-template>
  <!--  <ng-template pTemplate="footer">
      <tr [class]="resultData.footer">
        <td></td>
      </tr>
    </ng-template>-->
</p-table>
