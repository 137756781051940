<div class="container-fluid">
  <div class="row my-2">
    <div class="col">
      <mat-card class="card-box">
        <mat-card-title>Упаковка Заказов</mat-card-title>
        <mat-card-content>
          <div class="row">
            <div class="col">
              <mat-slide-toggle
                color="primary"
                (change)="setTypeOrderList($event)"
                #checkOrder
                >{{
                  checkOrder.checked ? "Все заказы" : "Заказы для упаковки"
                }}</mat-slide-toggle
              >
              <br />
              <mat-slide-toggle
                *ngIf="checkOrder.checked"
                color="primary"
                (change)="setTypeProdList($event)"
                #checkProd
                >{{
                  checkProd.checked
                    ? "Заказы по которым принято от 1 товара"
                    : ""
                }}</mat-slide-toggle
              >
              <br />
              <h3>Сводка по плановой упаковке товаров</h3>
              <table
                class="table table-bordered"
                *ngIf="loadPackDashboard$ | async as loadPackDashboard"
              >
                <tr>
                  <td rowspan="2"></td>
                  <td colspan="2" class="text-center">Сегодня</td>
                  <td colspan="2" class="text-center">Завтра</td>
                  <td colspan="2" class="text-center">После завтра</td>
                </tr>
                <tr>
                  <td>План</td>
                  <td>Факт</td>
                  <td>План</td>
                  <td>Факт</td>
                  <td>План</td>
                  <td>Факт</td>
                </tr>
                <tr>
                  <td>Заказы</td>
                  <td
                    class="cursor-pointer text-center"
                    (click)="loadDashboard('', loadPackDashboard.today)"
                  >
                    {{
                      +loadPackDashboard.orderToday +
                        +loadPackDashboard.orderTodaySuccess
                    }}
                  </td>
                  <td
                    class="cursor-pointer text-center"
                    (click)="loadDashboard('', loadPackDashboard.today)"
                  >
                    {{ loadPackDashboard.orderTodaySuccess }} |
                    <b>
                      {{
                        srv.roundNumber(
                          (loadPackDashboard.orderTodaySuccess * 100) /
                            (+loadPackDashboard.orderToday +
                              +loadPackDashboard.orderTodaySuccess)
                        )
                      }}
                      %
                    </b>
                  </td>
                  <td
                    class="cursor-pointer text-center"
                    (click)="loadDashboard('', loadPackDashboard.tomorrow)"
                  >
                    {{
                      +loadPackDashboard.orderTomorrow +
                        +loadPackDashboard.orderTomorrowSuccess
                    }}
                  </td>
                  <td
                    class="cursor-pointer text-center"
                    (click)="loadDashboard('', loadPackDashboard.tomorrow)"
                  >
                    {{ loadPackDashboard.orderTomorrowSuccess }} |
                    <b>
                      {{
                        srv.roundNumber(
                          (loadPackDashboard.orderTomorrowSuccess * 100) /
                            (+loadPackDashboard.orderTomorrow +
                              loadPackDashboard.orderTomorrowSuccess)
                        )
                      }}
                      %
                    </b>
                  </td>
                  <td
                    class="cursor-pointer text-center"
                    (click)="loadDashboard('', loadPackDashboard.afterTomorrow)"
                  >
                    {{
                      +loadPackDashboard.orderAfterTomorrow +
                        +loadPackDashboard.orderAfterTomorrowSuccess
                    }}
                  </td>
                  <td
                    class="cursor-pointer text-center"
                    (click)="loadDashboard('', loadPackDashboard.afterTomorrow)"
                  >
                    {{ loadPackDashboard.orderAfterTomorrowSuccess }} |
                    <b>
                      {{
                        srv.roundNumber(
                          (loadPackDashboard.orderAfterTomorrowSuccess * 100) /
                            (+loadPackDashboard.orderAfterTomorrow +
                              +loadPackDashboard.orderAfterTomorrowSuccess)
                        )
                      }}
                      %
                    </b>
                  </td>
                </tr>
                <tr>
                  <td>Товары</td>
                  <td class="text-center">
                    {{
                      +loadPackDashboard.productToday +
                        +loadPackDashboard.productTodaySuccess
                    }}
                    <span
                      matTooltip="Товары с количеством более 4х шт"
                      matTooltipClass="tooltip"
                      >({{ loadPackDashboard.productSpecToday }})</span
                    >
                  </td>
                  <td class="text-center">
                    {{ loadPackDashboard.productTodaySuccess }} |
                    <b>
                      {{
                        srv.roundNumber(
                          (loadPackDashboard.productTodaySuccess * 100) /
                            (+loadPackDashboard.productToday +
                              +loadPackDashboard.productTodaySuccess)
                        )
                      }}
                      %
                    </b>
                  </td>
                  <td class="text-center">
                    {{
                      +loadPackDashboard.productTomorrow +
                        +loadPackDashboard.productTomorrowSuccess
                    }}
                    <span
                      matTooltip="Товары с количеством более 4х шт"
                      matTooltipClass="tooltip"
                      >({{ loadPackDashboard.productSpecTomorrow }})</span
                    >
                  </td>
                  <td class="text-center">
                    {{ loadPackDashboard.productTomorrowSuccess }} |
                    <b>
                      {{
                        srv.roundNumber(
                          (loadPackDashboard.productTomorrowSuccess * 100) /
                            (+loadPackDashboard.productTomorrow +
                              +loadPackDashboard.productTomorrowSuccess)
                        )
                      }}
                      %
                    </b>
                  </td>

                  <td class="text-center">
                    {{
                      +loadPackDashboard.productAfterTomorrow +
                        +loadPackDashboard.productAfterTomorrowSuccess
                    }}
                    <span
                      matTooltip="Товары с количеством более 4х шт"
                      matTooltipClass="tooltip"
                      >({{ loadPackDashboard.productSpecAfterTomorrow }})</span
                    >
                  </td>
                  <td class="text-center">
                    {{ loadPackDashboard.productAfterTomorrowSuccess }} |
                    <b>
                      {{
                        srv.roundNumber(
                          (loadPackDashboard.productAfterTomorrowSuccess *
                            100) /
                            (+loadPackDashboard.productAfterTomorrow +
                              +loadPackDashboard.productAfterTomorrowSuccess)
                        )
                      }}
                      %
                    </b>
                  </td>
                </tr>
              </table>
            </div>
            <div class="col">
              <h2>
                {{
                  checkOrder.checked
                    ? "Все активные заказы"
                    : "Заказы с плановой датой отгрузки " +
                      (today | date: "dd-MM-YYYY")
                }}
              </h2>
              <table
                class="table table-bordered"
                *ngIf="dashboard$ | async as dashboard"
              >
                <tr>
                  <th></th>
                  <th>Подтвержден</th>
                  <th>Собирается</th>
                  <th>Упаковывается</th>
                  <th>Ждет отгрузки</th>
                  <th>Ждем оплату</th>
                  <th>Отгружен</th>
                </tr>
                <tr>
                  <th>Новая почта</th>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(1, 1)"
                  >
                    {{ dashboard.successNP }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(1, 2)"
                  >
                    {{ dashboard.payNP }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(1, 3)"
                  >
                    {{ dashboard.packNP }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(1, 4)"
                  >
                    {{ dashboard.waitSendNP }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(1, 99)"
                  >
                    {{ dashboard.waitPayNP }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(1, 5)"
                  >
                    {{ dashboard.sendNP }}
                  </td>
                </tr>

                <tr>
                  <th>Деливери</th>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(3, 1)"
                  >
                    {{ dashboard.successDel }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(3, 2)"
                  >
                    {{ dashboard.payDel }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(3, 3)"
                  >
                    {{ dashboard.packDel }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(3, 4)"
                  >
                    {{ dashboard.waitSendDel }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(3, 99)"
                  >
                    {{ dashboard.waitPayDel }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(3, 5)"
                  >
                    {{ dashboard.sendDel }}
                  </td>
                </tr>

                <tr>
                  <th>Гюнсел</th>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(4, 1)"
                  >
                    {{ dashboard.successGun }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(4, 2)"
                  >
                    {{ dashboard.payGun }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(4, 3)"
                  >
                    {{ dashboard.packGun }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(4, 4)"
                  >
                    {{ dashboard.waitSendGun }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(4, 99)"
                  >
                    {{ dashboard.waitPayGun }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(4, 5)"
                  >
                    {{ dashboard.sendGun }}
                  </td>
                </tr>

                <tr>
                  <th>SAT</th>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(5, 1)"
                  >
                    {{ dashboard.successSat }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(5, 2)"
                  >
                    {{ dashboard.paySat }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(5, 3)"
                  >
                    {{ dashboard.packSat }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(5, 4)"
                  >
                    {{ dashboard.waitSendSat }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(5, 99)"
                  >
                    {{ dashboard.waitPaySat }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(5, 5)"
                  >
                    {{ dashboard.sendSat }}
                  </td>
                </tr>
                <tr>
                  <th>Укрпочта</th>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(8, 1)"
                  >
                    {{ dashboard.successUkr }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(8, 2)"
                  >
                    {{ dashboard.payUkr }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(8, 3)"
                  >
                    {{ dashboard.packUkr }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(8, 4)"
                  >
                    {{ dashboard.waitSendUkr }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(8, 99)"
                  >
                    {{ dashboard.waitPayUkr }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(8, 5)"
                  >
                    {{ dashboard.sendUkr }}
                  </td>
                </tr>
                <tr>
                  <th>Самовывоз</th>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(7, 1)"
                  >
                    {{ dashboard.successSelf }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(7, 2)"
                  >
                    {{ dashboard.paySelf }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(7, 3)"
                  >
                    {{ dashboard.packSelf }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(7, 4)"
                  >
                    {{ dashboard.waitSendSelf }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(7, 99)"
                  >
                    {{ dashboard.waitPaySelf }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(7, 5)"
                  >
                    {{ dashboard.sendSelf }}
                  </td>
                </tr>
                <tr>
                  <th class="text-center">Курьер</th>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(6, 1)"
                  >
                    {{ dashboard.successCur }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(6, 2)"
                  >
                    {{ dashboard.payCur }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(6, 3)"
                  >
                    {{ dashboard.packCur }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(6, 4)"
                  >
                    {{ dashboard.waitSendCur }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(6, 99)"
                  >
                    {{ dashboard.waitPayCur }}
                  </td>
                  <td
                    class="text-center"
                    style="cursor: pointer"
                    (click)="setFilter(6, 5)"
                  >
                    {{ dashboard.sendCur }}
                  </td>
                </tr>
                <tr>
                  <th class="bg-warning">Всего</th>
                  <th class="bg-warning">
                    {{
                      +dashboard.successNP +
                        +dashboard.successGun +
                        +dashboard.successDel +
                        +dashboard.successSat +
                        +dashboard.successSelf +
                        +dashboard.successUkr +
                        +dashboard.successCur
                    }}
                  </th>
                  <th class="bg-warning">
                    {{
                      +dashboard.payNP +
                        +dashboard.paySelf +
                        +dashboard.payGun +
                        +dashboard.paySat +
                        +dashboard.payDel +
                        +dashboard.payUkr +
                        +dashboard.payCur
                    }}
                  </th>
                  <th class="bg-warning">
                    {{
                      +dashboard.packNP +
                        +dashboard.packSelf +
                        +dashboard.packCur +
                        +dashboard.packSat +
                        +dashboard.packDel +
                        +dashboard.packUkr +
                        +dashboard.packGun
                    }}
                  </th>
                  <th class="bg-warning">
                    {{
                      +dashboard.waitSendNP +
                        +dashboard.waitSendSelf +
                        +dashboard.waitSendGun +
                        +dashboard.waitSendSat +
                        +dashboard.waitSendDel +
                        +dashboard.waitSendUkr +
                        +dashboard.waitSendCur
                    }}
                  </th>
                  <th class="bg-warning">
                    {{
                      +dashboard.waitPayNP +
                        +dashboard.waitPaySelf +
                        +dashboard.waitPayGun +
                        +dashboard.waitPaySat +
                        +dashboard.waitPayDel +
                        +dashboard.waitPayUkr +
                        +dashboard.waitPayCur
                    }}
                  </th>
                  <th class="bg-warning">
                    {{
                      +dashboard.sendNP +
                        +dashboard.sendSelf +
                        +dashboard.sendCur +
                        +dashboard.sendGun +
                        +dashboard.sendSat +
                        +dashboard.sendUkr +
                        +dashboard.sendDel
                    }}
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="row my-2">
    <div class="col">
      <p-table
        #dt1
        *ngIf="orderList$ | async as orderList; else loading"
        [value]="orderList"
        [(selection)]="selectedOrder"
        [rowsPerPageOptions]="[10, 25, 50, 100, 250, 500]"
        [rows]="250"
        dataKey="id"
        styleClass=" p-datatable-sm p-datatable-gridlines p-datatable-striped"
        [paginator]="true"
        currentPageReportTemplate="Показано с {first} по {last} из {totalRecords} строчек"
        [globalFilterFields]="[
          'orderNumber',
          'planDateSend',
          'recipient',
          'phone',
          'deliveryName'
        ]"
        (onRowSelect)="onRowSelect($event)"
      >
        >
        <ng-template pTemplate="caption">
          <div class="row">
            <div class="col">
              <h2>Список заказов за {{ tableHeaderInfo.date }}</h2>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col">
              <button
                mat-button
                class="btn btn-primary btn-round"
                (click)="getPackingOrderList()"
                matTooltip="Обновить"
                matTooltipClass="tooltip"
              >
                <mat-icon>cached</mat-icon>
              </button>
              <button
                mat-button
                class="btn btn-success ml-4"
                (click)="onPackingOrder()"
              >
                Упаковать
              </button>

              <button
                mat-button
                class="btn btn-primary ml-4"
                (click)="printCheck()"
              >
                Печать чеков
              </button>
              <button
                mat-button
                class="btn btn-outline-primary ml-4"
                (click)="printNotPrintCheck()"
              >
                Печать не распечатных чеков
              </button>
            </div>
            <div class="col">
              <div class="text-right">
                <span class="p-input-icon-left p-ml-auto">
                  <i class="pi pi-search"></i>
                  <input
                    pInputText
                    type="text"
                    (input)="dt1.filterGlobal($event.target.value, 'contains')"
                    placeholder="Поиск"
                  />
                </span>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th style="width: 3rem"></th>
            <th style="width: 3rem">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th>Дата заказа</th>
            <th>Заказ</th>
            <th style="width: 8rem">Оплата</th>
            <th style="width: 8rem">Оценочная стоимость</th>
            <th style="width: 8rem">Cумма заказа</th>
            <th style="width: 8rem">Адрес</th>
            <th>Получатель</th>
            <th>Телефон</th>
            <th>Перевозщик</th>
            <th>Плановая дата отгрузки</th>
            <th>Менеджер</th>
            <th>Комментарий к отгрузке</th>
          </tr>
        </ng-template>
        <ng-template
          pTemplate="body"
          let-columns="columns"
          let-expanded="expanded"
          let-orderInfo
        >
          <tr>
            <td style="width: 3rem">
              <button
                type="button"
                pButton
                [pRowToggler]="orderInfo"
                class="p-button-text p-button-rounded p-button-plain"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
              ></button>
            </td>
            <td>
              <p-tableCheckbox [value]="orderInfo"></p-tableCheckbox>
            </td>
            <td>{{ orderInfo.createDate | date: "dd-MM-yyyy HH:mm:ss" }}</td>
            <td>
              <a
                *ngFor="let item of orderInfo.orderNumber.split(',')"
                class="link"
                [href]="'/crm/order-card/' + item"
                target="_blank"
              >
                {{ item }}
              </a>
              <div class="text-danger" *ngIf="+orderInfo.avtonova == 1">
                Склад 140
              </div>
              <div class="text-danger" *ngIf="+orderInfo.vavilon == 1">
                Склад 110
              </div>

              <!--         <a
                class="link"
                [href]="'/crm/order-card/' + orderInfo.orderNumber"
                target="_blank"
              >
                {{ orderInfo.orderNumber }}
              </a>-->
              <!--
              <a
                *ngFor="let item of orderInfo.orderNumber.split(',')"
                class="link"
                [href]="'/crm/order-card/' + item"
                target="_blank"
              >
                {{ item }}
              </a>-->
              <mat-icon
                matTooltip="Чек распечатан"
                matTooltipClass="tooltip"
                *ngIf="+orderInfo.print >= 1"
                >print
              </mat-icon>
            </td>
            <td>
              <div *ngIf="orderInfo.payment == 0 && orderInfo.paymentPart > 0">
                <mat-icon
                  class="text-success"
                  matTooltip="Заказ оплачен"
                  matTooltipClass="tooltip"
                  >monetization_on
                </mat-icon>
              </div>
              <div *ngIf="orderInfo.paymentPart > 0 && orderInfo.payment != 0">
                <mat-icon
                  class="text-warning"
                  matTooltip="Заказ оплачен частично"
                  matTooltipClass="tooltip"
                  >monetization_on
                </mat-icon>
              </div>
              {{ orderInfo.payName }}
            </td>
            <td>{{ orderInfo.costOrder }}</td>
            <td>{{ orderInfo.cost }}</td>
            <td>{{ orderInfo.address }}</td>
            <td>{{ orderInfo.recipient }}</td>
            <td>{{ orderInfo.phone }}</td>
            <td>{{ orderInfo.deliveryName }}</td>
            <td>
              {{ orderInfo.planDateSend | date: "dd-MM-yyyy" }}
              <span
                *ngIf="orderInfo.planDateSendTime"
                class="text-danger bold"
                >{{ orderInfo.planDateSendTime }}</span
              >
            </td>
            <td>
              {{ orderInfo.manager }}
            </td>
            <td>
              {{ orderInfo.deliveryComment }}
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-product>
          <tr>
            <td colspan="14" style="background: #c0c0c0">
              <div style="margin-left: 5rem; margin-top: 1rem">
                <p-table [value]="product.product" dataKey="id">
                  <ng-template pTemplate="header">
                    <tr>
                      <th pSortableColumn="nomen">
                        Номенклатура
                        <p-sortIcon field="nomen"></p-sortIcon>
                      </th>
                      <th pSortableColumn="katalNumber" style="width: 20rem">
                        Каталожный
                        <p-sortIcon field="katalNumber"></p-sortIcon>
                      </th>
                      <th pSortableColumn="brand" style="width: 20rem">
                        Бренд
                        <p-sortIcon field="brand"></p-sortIcon>
                      </th>
                      <th pSortableColumn="cost" style="width: 10rem">
                        Цена
                        <p-sortIcon field="cost"></p-sortIcon>
                      </th>
                      <th pSortableColumn="count" style="width: 10rem">
                        Кол-во
                        <p-sortIcon field="count"></p-sortIcon>
                      </th>
                      <th pSortableColumn="action" style="width: 10rem">
                        Статус
                        <p-sortIcon field="action"></p-sortIcon>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-order>
                    <tr>
                      <td>{{ order.nomen }}</td>
                      <td>{{ order.katalNumber }}</td>
                      <td>{{ order.brand }}</td>
                      <td>{{ order.cost }}</td>
                      <td>{{ order.count }}</td>
                      <td>{{ order.action }}</td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <ng-template #loading>
        <div class="d-flex justify-content-center my-5">
          <mat-spinner diameter="25"></mat-spinner>
        </div>
      </ng-template>
    </div>
  </div>
</div>
